<template>
	<div class="">
	<el-row class="registerCon">
		<el-col :span="24">
			<div class="registerSuCon">
				<h2>{{$t('i18nn_57810f3904cf54f0')}}</h2>
				<!-- <div class="registerSu"> -->
					<!-- <h3><span>{{$t('i18nn_8d2f2e05f1d8ff5e')}}</span></h3> -->
					<!-- <p>{{$t('i18nn_5be1d9322af32101')}}</p> -->
				<!-- </div> -->

				<!-- <h3>{{$t('i18nn_57810f3904cf54f0')}}</h3> -->
				<div style="padding: 10px 0; line-height: 200%;">
				<p>本协议是您与互易天下（简称"本站"，网址：http://www.hytx.com）所有者互易天下网络科技有限公司（以下简称为"互易天下"）之间就互易天下服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击"同意并继续"按钮后，本协议即构成对双方有约束力的法律文件。</p>

				<h4>第1条 本站服务条款的确认和接纳</h4>
				<p>1.1本站的各项在线服务的所有权和运作权归互易天下所有。您同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。您确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。</p>
				<p>1.2您点击同意本协议，即视为您确认自己具有享受在线购买商品及享受服务等相应的权利和行为能力，能够独立承担法律责任。</p>
				<p>1.3互易天下保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</p>

				<h4>第2条 本站服务</h4>
				<p>2.1互易天下通过互联网依法为您提供钢材、木材、棉花等信息和服务，您在完全同意本协议及本站规定的情况下，方有权使用本站的相关服务。</p>

				<h4>第3条 用户信息</h4>
				<p>3.1您应本着个人、厂家诚信向本站提供注册资料，您同意：提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新其注册资料。如果您提供的注册资料不合法有效、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且互易天下保留终止您使用互易天下各项服务的权利。</p>
				<p>3.2您知悉并同意，为方便您享受本站提供的浏览、下单锁货等服务，本站将储存您在使用时的必要信息，包括但不限于您及厂家的真实姓名/名称、通信地址、联系方式、营业执照等，本站将予以严格保密，未经您的授权或法律法规另有规定的情形外，本站不会向第三方披露您或厂家的隐私信息。</p>
				<p>3.3您注册成功后，将产生密码等账户信息，您可以根据本站提示修改您的密码。您应谨慎合理的保存、使用密码。您若发现任何非法使用您账号或存在安全漏洞的情况，请立即通知本站。否则，由于您自身的疏忽而导致账号信息泄露等后果，由您自行承担。</p>
				<p>3.4您充分理解并同意，互易天下拥有通过邮件、短信、电话等形式，向在本站注册、购物的您发送订单信息、促销活动等告知信息的权利。</p>
				<p>3.5您若将在本站注册获得的账户借给他人使用，则必须承担由此产生的全部责任，且实际使用人需承担连带责任。</p>
				<p>3.6您充分理解，为配合行政监管机关、司法机关执行工作，在法律规定范围内，互易天下有权使用您的注册信息、密码等信息，登陆进入您的注册账户，进行证据保全，包括但不限于公证、见证等。</p>

				<h4>第4条 厂家账号</h4>
				<p>4.1为享有包括采购等更多的服务和平台操作功能，您可以申请厂家账号，首先需具备注册资格，您必须确保系依照中华人民共和国法律法规设立的合法组织，并具有在中华人民共和国境内取得工商经营主体资格，并按平台提示上传相应证件。如您没有前述主体资格，本站有权拒绝您注册或认证厂家账户的申请，据此造成的损失由您自行承担。</p>
				<p>4.2在您按照互易天下注册页面提示填写信息，阅读并同意本协议并完成全部注册程序，您即为互易天下厂家账号；在您按照互易天下认证页面上传符合要求的证件并经互易天下审核通过后，您即为互易天下认证后的厂家账号。</p>
				<p>4.3您在成功完成厂家账号认证后，您可以通过互易天下平台在线交易、发布资源信息、发布求购信息、下载资源单等厂家会员服务。</p>
				<p>4.4您申请或认证厂家账号时，您设置的厂家信息不得侵犯或涉嫌侵犯他人合法权益。如您连续12个月未有使用手机号和密码登录互易天下及信息更新记录或实际使用互易天下服务的行为，互易天下保留对您中止或终止提供服务并注销账户的权利。</p>

				<h4>第5条 账户说明</h4>
				<p>5.1您应对您的手机号和密码的安全，以及对通过其手机号和密码实施的行为负责。您在互易天下注册的手机号和密码仅限于您自身内部人员进行使用，不得给予任何第三方使用，否则由此造成的损失由您自行承担，且互易天下保留暂停或终止服务。</p>
				<p>5.2除非有法律规定或司法裁定，且征得互易天下的同意，否则，手机号和密码不能以任何方式转让、赠与或继承。</p>
				<p>5.3您遗忘或丢失在互易天下注册的密码时，可与互易天下客户服务人员取得联系，在提供相关证明资料并经互易天下审核确认后，可找回密码。</p>

				<h4>第6条 信息的修改</h4>
				<p>您注册为厂家账号后，如注册登记信息发生变更，您必须及时、主动联系互易天下进行更新。因您未及时更新，造成您不能享受相关服务的、或造成账号及密码泄露等所有责任由您自行承担。</p>

				<h4>第7条 用户依法言行义务</h4>
				<p>7.1本协议依据国家相关法律法规规章制定，您同意严格遵守以下义务：</p>
				<p>（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</p>
				<p>（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；</p>
				<p>（3）不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
				<p>（4）不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；</p>
				<p>（5）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</p>
				<p>（6）不得教唆他人从事本条款所禁止的行为；</p>
				<p>（7）不得利用在本站注册的账户进行非法牟利经营活动； 您应不时关注并遵守本站不时公布或修改的各类合法规则规定。 本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知您的权利。 若您未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭帐号等措施。</p>
				<p>7.2您须对自己在本站的言论和行为承担法律责任，您若在本站上散布和传播反动、色情或其它违反国家法律的信息，本站的系统记录有可能作为您违反法律的证据。</p>

				<h4>第8条 商品信息</h4>
				<p>本站上的货物价格、数量、是否有货等商品信息将根据市场行情及销售情况随时发生变动，本站不作特别通知。由于网站货物信息数量庞大，虽然本站会尽最大努力保证您所浏览商品信息的准确性，但由于网络及电脑终端兼容性等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形请您知悉并理解；互易天下欢迎纠错，并会视情况给予纠错者一定的奖励。</p>

				<h4>第9条 责任限制及不承诺担保</h4>
				<p>除非另有明确的书面说明,本站及其所包含的或以其它方式通过本站提供给您的全部信息、资料、货物（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。 除非另有明确的书面说明,互易天下不对本站的运营及其包含在本网站上的信息、资料、货物（包括软件）和服务作任何形式的、明示或默示的声明或担保（中华人民共和国法律另有规定的除外）。如因不可抗力或其它本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，互易天下会合理地尽力协助处理善后事宜。</p>

				<h4>第10条 协议更新及用户关注义务</h4>
				<p>10.1根据国家法律法规变化及网站运营需要，互易天下有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。您可随时登陆查阅最新协议；您有义务不时关注并阅读最新版的协议及网站公告。如您不同意更新后的协议，可以且应立即停止接受互易天下依据本协议提供的服务；如您继续使用本网站提供的服务的，即视为同意更新后的协议。互易天下建议您在使用本站之前阅读本协议及本站的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
				<p>10.2任何经互易天下确认已经违反相关法律法规或本协议或互易天下使用规则某一项或多项规定的用户，互易天下有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销、决定是否给予暂停使用或终止使用的处理，且因此造成的一切后果由您自行承担。</p>
				<p>10.3用户与互易天下基于交易合作签署的其他书面协议与本协议不一致的，以双方书面签署的协议为准。</p>
				<p>10.4您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；互易天下因此遭受损失的，您也应当一并赔偿。</p>

				<h4>第11条 法律管辖和适用</h4>
				<p>本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。</p>

				<h4>第12条 其他</h4>
				<p>12.1互易天下所有者是指在政府部门依法许可或备案的互易天下经营主体。</p>
				<p>12.2本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
				<p>12.3本协议未明示授权的其他权利仍由互易天下保留，您在行使这些权利时须另外取得互易天下的书面许可。互易天下如果未行使前述任何权利，并不构成对该权利的放弃。</p>
				<p>12.4互易天下尊重用户和消费者的合法权利，本协议及本站上发布的各类规则、声明等其他内容，均是为了更好、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，互易天下将虚心接受并适时修改本协议及本站上的各类规则。</p>
			</div>

			
				
			</div>
		</el-col>
		
	</el-row>
	</div>
</template>



<script>
  export default {
  	name:"registrationAgreement",
  	//meta信息seo用
	  // metaInfo: {
	  //   title: '互易天下-厂家共享平台-注册协议', // set a title
	  //   meta: [{                 // set meta
	  //     name: '互易天下-注册协议',
	  //     content: '互易天下-厂家共享平台-注册协议'
	  //   }],
	  // },
    data(){
		return{
			
		}
	},
	created() {
		
	  },
	mounted() {
	    // console.log("mounted");
	    // this.init();
	},
	methods:{
	  
		
	    init(){
	    	
	    	
	    }
	},
  }
</script>

<style lang='less' scoped>
	// @import url(../../assets/css/module/login.less);
</style>
