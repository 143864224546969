<template>
	<div class="frameCon">
		<el-row class="registerCon">
			<el-col :span="16">
				<div class="registerAll">
					<h2><!-- 用户注册 -->{{$t("register.User_Registration")}}</h2>
					<div class="registerForm" v-loading="ruleForm.loading" :element-loading-text="$t('tips.Submitting')">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
							<!-- <el-form-item :label="$t('i18nn_a1805b0f0117f109')" prop="userName">
                  <el-input v-model="ruleForm.userName" maxlength="50" name="hyUserCode" :placeholder="$t('i18nn_04152d1213c1a758')"></el-input>
                </el-form-item> -->
							<el-form-item :label="$t('i18nn_94a8da065245abe4')" prop="reginType">
								<el-radio-group v-model="ruleForm.reginType" @change="changeRegType">
									<el-radio :label="'0'">{{$t('i18nn_e82c9fff83ec2d91')}}</el-radio>
									<el-radio :label="'1'">{{$t('i18nn_b2d4c7fffe79258e')}}</el-radio>
								</el-radio-group>
							</el-form-item>

							<div v-if="'1'==ruleForm.reginType">
								<el-form-item :label="$t('i18nn_b2d4c7fffe79258e')" prop="email">
									<el-input ref="email" :placeholder="$t('register.Please_Enter')+' '+$t('i18nn_b2d4c7fffe79258e')" v-model="ruleForm.email"
										name="hyUserEmail">
									</el-input>
								</el-form-item>
							</div>

							<div v-else>
								<el-form-item :label="$t('register.Mobile_phone')" prop="phoneNumber">
									<el-input ref="phoneNumber" :placeholder="$t('register.placeholder_phone')"
										v-model="ruleForm.phoneNumber" name="hyUserCode" class="input-with-select">
										<div slot="prepend">
											<HyAreaCodeSel :areaCode="ruleForm.areaCode" :areaCodeText="$t('register.China')"
												@selData="selMobileData"></HyAreaCodeSel>
										</div>
									</el-input>
								</el-form-item>
							</div>


							<el-form-item v-loading="loading_phone" :element-loading-text="$t('tips.Submitting')"
								:label="$t('register.Verification_Code')" prop="verificationCode">
								<el-input v-model="ruleForm.verificationCode" maxlength="8"
									:placeholder="$t('register.placeholder_Code')" style="width: 200px;"></el-input>
								<el-button type="warning" style="width: 190px;" @click="verCodeAction()"
									:disabled="isGetVerifCode.disabled">
									<span v-if="!isGetVerifCode.count">{{$t('register.Get_Code')}}</span>
									<span v-if="isGetVerifCode.count">{{isGetVerifCode.count}}{{$t('register.seconds')}}</span>
								</el-button>
							</el-form-item>


							<el-form-item :label="$t('register.Password')" prop="password">
								<el-input type="password" v-model="ruleForm.password" maxlength="50"
									:placeholder="$t('register.Please_Enter')+' '+$t('register.Password')" show-password></el-input>
							</el-form-item>
							<el-form-item :label="$t('register.Duplicate_password')" prop="password">
								<el-input type="password" v-model="ruleForm.rePassword" maxlength="50"
									:placeholder="$t('register.Please_Enter')+' '+$t('register.Password')" show-password></el-input>
							</el-form-item>
							<!-- <el-form-item :label="$t('register.Partner_phone')" prop="partnerPhone" v-if="!hasPartnerPhone">
                <el-input type="tel" v-model="ruleForm.partnerPhone"  :placeholder="$t('register.Please_Enter')+' '+$t('register.Partner_phone')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('register.Partner_phone')" prop="partnerPhone" v-else>
                <el-input type="tel" v-model="ruleForm.partnerPhone"  readonly></el-input>
              </el-form-item> -->
							<el-form-item class="checkProtocol">
								<el-checkbox-group v-model="checkProtocol">
									<el-checkbox :label="$t('register.Read_agree')" name="type">
									</el-checkbox>
								</el-checkbox-group>
								<span class="protocolTit" @click="showAgreement()">{{$t('register.Registration_Agreement')}}</span>
							</el-form-item>
							<!-- </el-form-item> -->
							<!-- <div class="loginTipMsg">已有账号，<router-link to="/login">{{$t('i18nn_0fff44c827a4f3b6')}}</router-link></div> -->
						</el-form>
						<el-button type="primary" style="width: 100%;" :disabled="!checkProtocol"
							@click="submitForm('ruleForm')">{{$t('register.register')}}</el-button>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<ul class="registerOther">
					<li>{{$t('register.account_msg')}}</li>
					<li class="loginLink">
						<router-link to="/loginFrame/login">{{$t('register.Sign_in')}}</router-link>
					</li>
					<li>{{$t('register.customer_service_msg')}}</li>
					<li>
						{{$t('register.Telephone')}}：
						<span class="phoneNum">{{$store.state.CusServiceTelText}}</span>
						<!-- <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=659604438&site=qq&menu=yes">QQ客服</a> -->
						<!-- <el-button type="text" @click="contactService" style="font-size: 16px;"> -->
						<!-- 在线客服 --><!-- {{$t('register.Online_Service')}} -->
						<!-- </el-button> -->
					</li>
				</ul>
			</el-col>
		</el-row>
		<!-- <el-dialog :close-on-click-modal="false"  :title="$t('register.Registration_Agreement')" :visible.sync="dialogTableVisible" top="10" custom-class="myFullDialog2"> -->
		<el-drawer :wrapperClosable="false" :title="$t('register.Registration_Agreement')" append-to-body
			:visible.sync="dialogTableVisible" :direction="'rtl'" size="900px">

			<registration-agreement ref="RegistrationAgreement"></registration-agreement>
		</el-drawer>
		<!-- </el-dialog> -->
	</div>
</template>

<script>
	import RegistrationAgreement from './registrationAgreement.vue'

	// import crypto from "crypto" //在使用的页面引入加密插件
	import HyAreaCodeSel from '../Common/HyAreaCodeSel.vue'

	export default {
		name: "register",
		components: {
			RegistrationAgreement,
			HyAreaCodeSel
		},
		//meta信息seo用
		// metaInfo: {
		//   title: this.metaTitle, // set a title
		//   // meta: [{ // set meta
		//   //   name: 'description',
		//   //   content: '互易天下-厂家共享平台-注册'
		//   // }],
		// },
		// metaInfo () {
		//   return {
		//     title: this.$t("meta.main"),
		//   }
		// },
		data() {
			return {
				// metaTitle:this.$t("meta.main"),
				//
				serArea: "",

				checkProtocol: true,
				loadPackNum: "",
				loading_phone: false,
				isGetVerifCode: {
					disabled: false,
					count: '',
					timer: null,

				},
				// isShowGetVerifCode: false,
				// hasPartnerPhone: false,

				// areaCodeList: this.$store.state.areaCodeList,

				ruleForm: {
					loading: false,
					// userCode: '',
					// userType: '',
					// password: '',

					"reginType": '0', //1--邮箱，'0'--手机号
					"email": "",

					password: '',
					rePassword: '',
					areaCode: '86', //手机国际区号
					phoneNumber: '',
					verificationCode: '',
					// partnerPhone: '',

				},
				rules: {
					// userName: [
					//   { required: true, message: this.$t('i18nn_04152d1213c1a758'), trigger: 'blur' },
					//   { min: 2, max: 20, message: '用户名长度在 2 到 20 个字符', trigger: 'blur' }
					// ],
					reginType: [{
						required: true,
						message: (this.$t('register.Please_Enter') + ' ' + this.$t('i18nn_94a8da065245abe4')),
						trigger: 'change'
					}, ],
					email: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('i18nn_b2d4c7fffe79258e')),
							trigger: 'blur'
						},
						{
							pattern: this.$Validate.REGEX_EMAIL,
							message: this.$t('i18nn_474da87fdb795187')
						}
					],
					password: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('register.Password')),
							trigger: 'blur'
						},
						// { min: 6, max: 30, message: '请输入6~18位，字母和数字组合的密码', trigger: 'blur' }
						{
							pattern: this.$Validate.REGEX_PASSWORD,
							message: this.$t('register.placeholder_must_pwd')
						}
					],
					rePassword: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('register.Duplicate_password')),
							trigger: 'blur'
						},
						// { pattern: this.rules.password, message: this.$t('i18nn_b978bd71e959b620')}
						// this.$Validate.REGEX_PHONE
						{
							pattern: this.$Validate.REGEX_PASSWORD,
							message: this.$t('register.placeholder_must_pwd')
						}
						// { min: 6, max: 30, message: '请输入6~18位，字母和数字组合的密码', trigger: 'blur' }
					],
					areaCode: [{
						required: true,
						message: this.$t('register.placeholder_areaCode'),
						trigger: 'change'
					}, ],
					phoneNumber: [{
							required: true,
							message: (this.$t('register.placeholder_phone')),
							trigger: 'blur'
						},
						{
							pattern: this.$Validate.REGEX_PHONE_International,
							message: this.$t('register.placeholder_must_phone')
						}
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					],
					verificationCode: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('i18nn_d49a93bb265a311d')),
							trigger: 'blur'
						},
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					],
					// partnerPhone: [
					//   { pattern: this.$Validate.REGEX_PHONE_International, message: (this.$t('register.Please_Enter')+' '+this.$t('register.Partner_phone')) }
					//   // { required: true, message: this.$t('i18nn_f1ee1d97e869e447'), trigger: 'blur' },
					//   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					// ],
					// userType: [
					//   { required: true, message: this.$t('i18nn_eae360b6837f15e9'), trigger: 'change' }
					// ]
				},
				dialogTableVisible: false,
			}
		},
		created() {
			this.$parent.loginTitle = this.$t("register.registerTitle");
			//手机区域代码
			// this.$store.dispatch('UPDATE_PHONE_AREACODE_DATA').then(res => {
			//   console.log(this.$t('i18nn_09a6f3a7fdcdaae1'), res);
			//   this.areaCodeList = res;
			// })
			// .catch(res => {
			//   console.log(this.$t('i18nn_6b0ab3e65c3be5f6'), res);
			//   this.$message.warning(this.$t('i18nn_6b0ab3e65c3be5f6'));
			// });
		},
		mounted() {
			console.log("mounted");
			// if (this.$route.query.partnerPhone) {
			//   this.ruleForm.partnerPhone = this.$route.query.partnerPhone;
			//   this.hasPartnerPhone = true;
			// } else if(this.$store.getters.getSharePhone){
			//   this.ruleForm.partnerPhone = this.$store.getters.getSharePhone;
			//   this.hasPartnerPhone = true;
			// }
			// this.init();
		},
		methods: {
			//选择区域代码
			selMobileData(item) {
				this.ruleForm.areaCode = item.codeText; //手机国际区号
			},

			//联系客服
			// contactService(row) {
			// 	console.log(this.$t('i18nn_e097bb9b1216bfd8'));
			// },

			//验证重复密码
			verifPassWord() {
				if (this.ruleForm.password !== this.ruleForm.rePassword) {
					this.$alert(this.$t("register.ver_re_pwd"), this.$t('tips.tipsTitle'), {});
					return false;
				} else {
					return true;
				}
			},
			//发送验证码成功
			codeSendSuccess() {
				// this.$message.success(this.$t('i18nn_4733de26e1c2e985') + ',' + this.$t('i18nn_cdf92fd92be6533d'));
				this.$alert(this.$t('i18nn_4733de26e1c2e985') + ',' + this.$t('i18nn_cdf92fd92be6533d'), this.$t('tips.tipsTitle'), {
					type: 'success',
					// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				});
				try {
					this.$refs.ruleForm.validateField('verificationCode');
				} catch (e) {
					console.log(e);
				}
				this.isGetVerifCode.disabled = true;
				let _this = this;
				setTimeout(function() {
					_this.isGetVerifCode.disabled = false;
				}, 1000 * 60);
				this.getCode();
			},
			//倒计时
			getCode() {
				const TIME_COUNT = 60;
				let _this = this;
				if (!this.isGetVerifCode.timer) {
					this.isGetVerifCode.count = TIME_COUNT;
					// this.isGetVerifCode.disabled = false;
					this.isGetVerifCode.timer = setInterval(() => {
						if (_this.isGetVerifCode.count > 0 && _this.isGetVerifCode.count <= TIME_COUNT) {
							_this.isGetVerifCode.count--;
						} else {
							_this.isGetVerifCode.disabled = false;
							clearInterval(_this.isGetVerifCode.timer);
							_this.isGetVerifCode.timer = null;
						}
					}, 1000)
				}
			},
			changeRegType() {
				console.log('changeRegType');
				try {
					this.$refs.ruleForm.validateField('phoneNumber');
					this.$refs.ruleForm.validateField('email');
				} catch (e) {
					console.log(e);
				}
				// this.ruleForm.phoneNumber = "";
				// this.ruleForm.email = "";
				this.ruleForm.verificationCode = "";
			},
			//验证码
			verCodeAction() {
				if ('1' == this.ruleForm.reginType) { //邮箱
					this.emailCodeData();
				} else { //手机号
					this.phoneCodeData();
				}
			},
			//邮箱获得验证码
			emailCodeData() {
				if (!(this.$Validate.REGEX_EMAIL.test(this.ruleForm.email))) {
					this.$alert(this.$t('i18nn_2fca45d67fdcae33'), this.$t('tips.tipsTitle'), {
						type: "warning",
					});
					return false;
				}
				this.loading_phone = true;
				this.$http.post(this.$urlConfig.ServiceEmailCode, {
						email: this.ruleForm.email,
						codeType: '0',
					})
					.then(({
						data
					}) => {
						this.loading_phone = false;
						if (200 == data.code) {
							this.codeSendSuccess();
						} else {
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: 'warning',
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_0f00eaea829fd543'));
						this.loading_phone = false;
						this.$alert(this.$t("tips.requestErrorData"), this.$t('tips.tipsTitle'), {
							type: 'warning',
						});
					});
			},
			//手机获得验证码
			phoneCodeData() {
				if (!(this.$Validate.REGEX_PHONE_International.test(this.ruleForm.phoneNumber))) {
					this.$alert(this.$t("register.placeholder_phone"), this.$t('tips.tipsTitle'), {
						type: "warning",
					});
					return false;
				}
				// let _this = this;
				this.loading_phone = true;
				this.$http.post(this.$urlConfig.ServicePhoneCode, {
						areaCode: this.ruleForm.areaCode,
						phoneNumber: this.ruleForm.phoneNumber,
						codeType: '0',
					})
					.then(({
						data
					}) => {
						// console.log(this.$t('i18nn_a034ab2810999eb7'));
						// console.log(data);
						this.loading_phone = false;
						if (200 == data.code) {
							this.codeSendSuccess();
						} else {
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: 'warning',
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_0f00eaea829fd543'));
						this.loading_phone = false;
						this.$alert(this.$t("tips.requestErrorData"), this.$t('tips.tipsTitle'), {
							type: 'warning',
						});
					});
			},
			//    注册提交
			submitForm(formName) {
				// this.$router.replace({name:'registerSuccess'});
				if (this.verifPassWord()) {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.registerAction();
						} else {
							console.log('error submit!!');
							this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
								type: "warning",
								// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							return false;
						}
					});
				}

			},
			//显示协议
			showAgreement() {
				this.dialogTableVisible = true;
			},

			//注册请求
			registerAction() {
				// console.log(this.$t('i18nn_c39705483df9ffdb'));
				this.ruleForm.loading = true;
				this.$http.post(this.$urlConfig.HyRegister, {
						"reginType": this.ruleForm.reginType,
						password: this.ruleForm.password,
						areaCode: this.ruleForm.areaCode,
						phoneNumber: this.ruleForm.phoneNumber,
						"email": this.ruleForm.email,
						// userName: that.ruleForm.userName,
						verificationCode: this.ruleForm.verificationCode,
						// partnerPhone: this.ruleForm.partnerPhone,
					})
					.then(({
						data
					}) => {
						// console.log(this.$t('i18nn_aaf11ed618ec2053'));
						// console.log(data);
						this.ruleForm.loading = false;
						if (200 == data.code) {
							this.loginSuccess(data.data, data.permission)
						} else {
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: 'warning',
							});
						}
					})
					.catch((error) => {
						console.log(error);
						this.ruleForm.loading = false;
						this.$message.warning(this.$t("tips.requestErrorData"));

					});
			},
			//注册成功
			loginSuccess(userinfo, role) {
				this.$store.dispatch('USER_SIGNOUT');
				// // console.log(this.$store.state.login.userInfo);
				this.$store.dispatch('UPDATE_USERINFO', userinfo);
				if (this.$store.getters.getUserInfo && this.$store.getters.getUserInfo.id) {
					console.log('getUserInfo success', this.$store.getters.getUserInfo);
					this.$message({
						message: this.$t("register.register_was_successful"),
						type: 'success'
					});
					// this.$router.replace({name:'home'});
					this.$router.replace({
						name: 'registerSuccess'
					});
				} else {
					console.log('getUserInfo error111', this.$store.getters.getUserInfo);
					let _this = this;
					this.$store.dispatch('USER_SIGNOUT');
					this.$store.dispatch('UPDATE_USERINFO', userinfo);
					//第一次存不到缓存bug
					if (this.$store.getters.getUserInfo && this.$store.getters.getUserInfo.id) {
						console.log('getUserInfo success', this.$store.getters.getUserInfo);
						this.$message({
							message: this.$t("register.register_was_successful"),
							type: 'success'
						});
						// this.$router.replace({name:'home'});
						this.$router.replace({
							name: 'registerSuccess'
						});
					} else { //第二次存不到 刷新浏览器
						//再次取不到刷新浏览器
						console.log('getUserInfo error222', this.$store.getters.getUserInfo);
						this.$message({
							message: this.$t("register.register_was_successful"),
							type: 'success'
						});
						this.$router.replace({
							name: 'registerSuccess'
						});
						window.location.reload();
					}
				}
			},
		},
	}
</script>
<style lang='less' scoped>
	// @import url(../../assets/css/module/login.less);
</style>